.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiPickersDay-root.Mui-selected {
  background-color: rgba(57, 161, 125, 0.25) !important;
  color: black !important;
  border-radius: 10% !important;
  border-right: 1px solid #a51428 !important;
  border-left: 1px solid #a51428 !important;
}

.MuiPickersDay-today {
  border-radius: 100% !important;
}

.MuiDataGrid-cell--editable {
  background-color: rgba(57, 161, 125, 0.25) !important;
  border: 1px solid #1c1c1c !important;
  border-radius: 5px;
}
